/* ------------------------------------------------------------------------ * * Helpers
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Colors
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Fonts                                                                    *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Widths                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Break Points                                                             *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layers                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Functions
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Mixins
\* ------------------------------------------------------------------------ */
._dark {
  color: #000000 !important; }

._light {
  color: #FFFFFF !important; }

._big {
  font-size: 1.25em !important; }

._small {
  font-size: 0.75em !important; }

._bold {
  font-weight: 700 !important; }

._italic {
  font-style: italic !important; }

._textcenter {
  text-align: center !important; }

._textleft {
  text-align: left !important; }

._textright {
  text-align: right !important; }

._nowrap {
  white-space: nowrap !important; }

._center {
  margin-left: auto !important;
  margin-right: auto !important; }

._left {
  float: left !important; }

._right {
  float: right !important; }

._nomargin {
  margin: 0 !important; }

._nopadding {
  padding: 0 !important; }

._block {
  display: block !important; }

._flex {
  display: -ms-flexbox !important;
  -js-display: flex !important;
  display: flex !important; }

._visuallyhidden {
  background: #f1f1f1 !important;
  border-radius: 0 !important;
  box-shadow: 0em 0em 0.14286em 0.14286em rgba(0, 0, 0, 0.6) !important;
  color: #B7202A !important;
  display: block !important;
  font-family: "Source Sans Pro", "Arial", "Helvetica", sans-serif !important;
  font-size: 14px !important;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  height: auto !important;
  left: -999em !important;
  letter-spacing: normal !important;
  line-height: normal !important;
  outline: none !important;
  padding: 1.07143em 1.64286em 1em !important;
  position: absolute;
  text-decoration: none !important;
  text-shadow: none !important;
  text-transform: none !important;
  top: -999em !important;
  white-space: nowrap;
  width: auto !important; }

._visuallyhidden:focus {
  left: 0.42857em !important;
  top: 0.42857em !important;
  z-index: 100000 !important; }

._primary {
  color: #751113 !important; }

._accent {
  color: #B7202A !important; }

._warning {
  color: #751113 !important; }

._sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important; }

@media screen and (min-width: 601px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .admin-bar ._sticky {
      top: 46px !important; } } }

@media screen and (min-width: 783px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .admin-bar ._sticky {
      top: 32px !important; } } }

/* visibility classes */
@media (pointer: fine) {
  ._touch {
    display: none !important; } }

@supports not (pointer: fine) {
  ._touch {
    display: none !important; } }

@media (pointer: course) {
  ._mouse {
    display: none !important; } }

@media print {
  ._noprint {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  ._tablet:not(._phone),
  ._notebook:not(._phone),
  ._desktop:not(._phone) {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  ._phone:not(._tablet),
  ._notebook:not(._tablet),
  ._desktop:not(._tablet) {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 79.9375em) {
  ._phone:not(._notebook),
  ._tablet:not(._notebook),
  ._desktop:not(._notebook) {
    display: none !important; } }

@media screen and (min-width: 80em) {
  ._phone:not(._desktop),
  ._tablet:not(._desktop),
  ._notebook:not(._desktop) {
    display: none !important; } }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Article
\* ------------------------------------------------------------------------ */
/* callout variant */
.article.-callout .article_title {
  font-size: 1.25em; }

/* headline variant */
.article.-headline {
  margin-bottom: 1.25em;
  padding-bottom: 1.25em; }

.article.-headline .article_figure {
  width: 5.625em; }

.article.-headline .article_text {
  font-size: 1.125em; }

/* ------------------------------------------------------------------------ * * Ninja Forms
\* ------------------------------------------------------------------------ */
/* stylelint-disable selector-class-pattern, selector-max-type */
/* ------------------------------------------------------------------------ * * Opinionated Tweaks
\* ------------------------------------------------------------------------ */
.nf-field-container .user-content h2 {
  font-size: 3em; }

/* ------------------------------------------------------------------------ * * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */
/* stylelint-disable selector-class-pattern, selector-max-type */
.swiper-picture {
  border-bottom-width: 12px;
  border-bottom-width: 0.75rem;
  border-image: url("../media/background-static.jpg") 0 0 12 repeat; }

.swiper-user-content h1 {
  font-size: 3.25em; }

/* ------------------------------------------------------------------------ * * Widget
\* ------------------------------------------------------------------------ */
.widget_user-content {
  /* stylelint-disable selector-max-type */
  /* stylelint-enable */ }
  .widget_user-content h2 {
    font-size: 1.9375em; }
  .widget_user-content p,
  .widget_user-content ol,
  .widget_user-content ul,
  .widget_user-content table {
    font-size: 1.125em; }

/* background variant */
.widget.-background {
  padding: 45px 15px;
  padding: 2.8125rem 0.9375rem; }

.widget.-background .widget_content {
  max-width: 180px;
  max-width: 11.25rem;
  padding-left: 10px;
  padding-left: 0.625rem;
  padding-right: 10px;
  padding-right: 0.625rem; }

/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
.header_inner {
  border-bottom-width: 12px;
  border-bottom-width: 0.75rem;
  border-image: url("../media/background-static.jpg") 0 0 12 repeat;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
  padding-top: 20px;
  padding-top: 1.25rem; }

.header_logo {
  margin-top: -2.5em;
  width: 16.25em; }

/* fullbleed variant */
.header-block.-fullbleed {
  border-bottom-width: 12px;
  border-bottom-width: 0.75rem;
  border-image: url("../media/background-static.jpg") 0 0 12 repeat;
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
  padding-top: 20px;
  padding-top: 1.25rem; }

.header-block.-fullbleed > .header_inner {
  border-bottom: 0;
  border-image: none;
  padding-bottom: 0;
  padding-top: 0; }

/* ------------------------------------------------------------------------ * * Content
\* ------------------------------------------------------------------------ */
.content_inner {
  padding-bottom: 50px;
  padding-bottom: 3.125rem;
  padding-top: 50px;
  padding-top: 3.125rem; }

/* fullbleed variant */
.content-block.-fullbleed {
  padding-bottom: 50px;
  padding-bottom: 3.125rem;
  padding-top: 50px;
  padding-top: 3.125rem; }

.content-block.-fullbleed > .content_inner {
  padding-bottom: 0;
  padding-top: 0; }

/* ------------------------------------------------------------------------ * * Callout
\* ------------------------------------------------------------------------ */
.callout-block {
  padding-bottom: 40px;
  padding-bottom: 2.5rem;
  padding-top: 40px;
  padding-top: 2.5rem; }

.callout_inner {
  padding-bottom: 35px;
  padding-bottom: 2.1875rem;
  padding-top: 35px;
  padding-top: 2.1875rem; }

/* fullbleed variant */
.callout-block.-fullbleed {
  padding-bottom: 35px;
  padding-bottom: 2.1875rem;
  padding-top: 35px;
  padding-top: 2.1875rem; }

.callout-block.-fullbleed > .callout_inner {
  padding-bottom: 0;
  padding-top: 0; }

/* ------------------------------------------------------------------------ * * Footer
\* ------------------------------------------------------------------------ */
.footer_inner {
  border-image: url("../media/background-static.jpg") 8 0 0 repeat;
  border-top-width: 12px;
  border-top-width: 0.75rem; }

@media screen and (max-width: 79.9375em) {
  .footer_widget {
    font-size: 1.25vw; } }

/* fullbleed variant */
.footer-block.-fullbleed {
  border-image: url("../media/background-static.jpg") 8 0 0 repeat;
  border-top-width: 12px;
  border-top-width: 0.75rem; }

.footer-block.-fullbleed > .footer_inner {
  border-image: none;
  border-top-width: 0; }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Content
\* ------------------------------------------------------------------------ */
/* image variant */
.content-block.-image {
  padding-bottom: 0; }

.content-block.-image .content_inner {
  overflow: hidden;
  padding-bottom: 50px;
  padding-bottom: 3.125rem;
  position: relative; }

.content-block.-image .content_title {
  position: relative;
  z-index: 1; }

.content-block.-image .content_figure {
  -ms-flex-align: end;
      align-items: flex-end;
  bottom: 0;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  max-width: 50%;
  padding-top: 16.666px;
  padding-top: 1.04167rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0; }

.content-block.-image .content_image {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto; }

.content-block.-image .content_user-content {
  position: relative;
  z-index: 1; }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Content
\* ------------------------------------------------------------------------ */
.content_sidebar {
  width: 350px;
  width: 21.875rem; }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */
/* stylelint-disable selector-class-pattern, selector-max-type */
.swiper-container {
  border: 0;
  border-bottom: 12px solid #9c9c9c;
  border-bottom: 0.75rem solid #9c9c9c;
  border-image: url("../media/background-static.jpg") 0 0 12 repeat; }

.swiper-picture {
  border-bottom: 0; }

.swiper-image {
  max-width: 1000px;
  max-width: 62.5rem;
  width: 66.66667%; }

.swiper-caption {
  background: none;
  bottom: 0;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  left: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0; }

.swiper-caption::before {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 20%);
  bottom: 0;
  content: "\0020";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 66.66667%;
  width: calc(66.66667% + 3.125rem);
  z-index: 0; }

.swiper-caption .swiper-caption-inner {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  padding: 0.9375em 1.25em 0.9375em 31.25em;
  position: relative;
  z-index: 1; }

.swiper-user-content h1 {
  font-size: 3.25em; }

/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Toolbar
\* ------------------------------------------------------------------------ */
.toolbar_inner {
  padding-bottom: 15px;
  padding-bottom: 0.9375rem;
  padding-top: 15px;
  padding-top: 0.9375rem; }

/* fullbleed variant */
.toolbar-block.-fullbleed {
  padding-bottom: 15px;
  padding-bottom: 0.9375rem;
  padding-top: 15px;
  padding-top: 0.9375rem; }

.toolbar-block.-fullbleed > .toolbar_inner {
  padding-bottom: 0;
  padding-top: 0; }

/* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
@media screen and (max-width: 85.375em) {
  .header-block {
    font-size: 1.171303074670571vw; } }

.header_logo {
  margin-top: -3.4375em; }

/* ------------------------------------------------------------------------ * * Hero
\* ------------------------------------------------------------------------ */
@media screen and (max-width: 85.375em) {
  .hero-block {
    font-size: 1.171303074670571vw; } }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
.header_logo {
  margin-top: -4.0625em;
  width: 19.375em; }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
